// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/forms.css';
/**
 * Form Bundle
 * Entry point to the forms common-chunk.
 * Expects `commons.js` to already be loaded.
 * Used by the Commerce cart page for form rendering.
 * See: `apps/Forms`
 */
YUI.add('squarespace-forms', function(Y) {

  Y.namespace('Squarespace.Widgets');

  // aliasing the old async form component
  Y.Squarespace.Widgets.Form = Y.Squarespace.Widgets.AsyncForm;

  if (__DEV__) {
    console.log('Forms bundle loaded.');
  }

}, '1.0', {
  requires: ['squarespace-async-form']
});
